
:root {
  --global-blue-dark: #3e7787;
  --global-blue-light: #eefcfb;
  --global-nav-bar-menu-text-icons: #2e5d69;
  --global-green-dark: #7b9f03;
  --global-green-light: #f6fbeb;
  --global-orange-dark: #cc581f;
  --global-orange-light: #fff4f0;
  --white: #fff;
  --gray-20: #ededed;
  --gray-10: #f7f7f7;
  --gray-50: #cfcfcf;
  --gray-70: #979797;
  --black: #222;
  --competency-1-dark: #049c8c;
  --competency-1-light: #ebfcf2;
  --competency-2-dark: #1a82a7;
  --competency-2-light: #e9f6fc;
  --competency-3-dark: #cc581f;
  --competency-3-light: #fff4f0;
  --competency-4-dark: #5e2f82;
  --competency-4-light: #f3ebfc;
  --competency-5-dark: #ad2e6f;
  --competency-5-light: #ffebf5;
  --competency-6-dark: #c32627;
  --competency-6-light: #ffefef;
  --warning-alert: #d6941b;
  --success-alert: #039f25;
  --cda-focus-blue: #0026fd;
  --cda-alert-red: #e4273a;
}
.Rectangle {
  height: 38px;
  background-image: radial-gradient(circle at 6% 50%, var(--global-orange-light), #fdfaec 46%, var(--global-blue-light));
}

.WelcomeMessage {
  height: 76px;
  background-color: #fbfbfb;
  align-items: center;
}

.blue {
  height: 12px;
  background-color: var(--global-blue-light);
}

.green {
  height: 12px;
  background-color: #f6fbeb;
}
.gold {
  height: 12px;
  background-color: #fff4f0;
}

.error {
  color: red;
}

.error .MuiFormLabel-root {
  color: red;
}

.rs-sidenav-nav > .rs-sidenav-item,
.rs-sidenav-nav > .rs-dropdown .rs-dropdown-toggle {
  font-size: 16px;
  line-height: 1.25;
  padding-left: 16px !important;
  width: 100%;
  white-space: normal;
}

.rs-sidenav-collapse-in .rs-dropdown-item,
.rs-sidenav-collapse-in .rs-dropdown-item-submenu > .rs-dropdown-item-toggle {
  padding: 1px 20px 11px 32px !important;
}

.nav-link {
  color: var(--global-blue-dark);
  font-weight: bold;
}

.nav-link:hover {
  color: var(--global-blue-dark);
  font-weight: bold;
  text-decoration: none; 
}
.rs-sidenav-item {
  color: var(--global-blue-dark) !important;
  font-weight: bold;
}

a.rs-sidenav-item:active,
a.rs-sidenav-item:visited,
a.rs-sidenav-item:focus {
  border-right: 5px solid green;
  color: var(--global-blue-dark);
  font-weight: bold;
  text-decoration: none;
}

a.rs-dropdown-item:active,
a.rs-dropdown-item:visited,
a.rs-dropdown-item:focus {
  border-right: 5px solid green;
  color: var(--global-blue-dark);
  font-weight: bold;
  text-decoration: none;
}

/* Learning.css */
.blue-expand-icon {
  color: #1a82a7;
}

